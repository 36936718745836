import React from "react";
import { ARTICLE_7 } from "shared/Types/articles";
import { useArticlesData } from "shared/Hooks/Articles/useArticlesData";

import { Image, Hero, Layout, Text } from "components/BlogLayout";

const Article_7 = () => {
  const data = useArticlesData(ARTICLE_7);

  return (
    <Layout>
      <Hero data={data} />
      <Text data={data.text.first} />
      <Image data={data.images.first} />
      <Text data={data.text.second} />
      <Text data={data.text.third} />
      <Image data={data.images.second} />
      <Text data={data.text.fourth} />
      <Image data={data.images.third} />
      <Text data={data.text.fifth} />
      <Image data={data.images.fourth} />
      <Text data={data.text.sixth} />
    </Layout>
  );
};

export default Article_7;
